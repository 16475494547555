import { useState, useEffect } from 'react'

export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null
  return wpLink.replace(wordPressUrl, '')
}

export const slugify = string => {
  return string.toLowerCase().replace(/ /g, '-')
}

export const isBrowser = typeof window !== `undefined`

export const scrollTo = (elementY, duration) => {
  if (typeof window !== 'undefined') {
    const startingY = window.pageYOffset
    const diff = elementY - startingY
    let start

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1)
      window.scrollTo({
        top: startingY + diff * percent,
        left: 0,
        behaviour: 'smooth',
      })

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })
  }
}

export const objectToQuerystring = obj => {
  return Object.keys(obj).reduce((str, key, i) => {
    let delimiter
    let val
    delimiter = i === 0 ? '?' : '&'
    key = encodeURIComponent(key)
    val = encodeURIComponent(obj[key])
    return [str, delimiter, key, '=', val].join('')
  }, '')
}

export const getUrlVars = () => {
  const vars = []
  let hash
  const query_string = isClient && window.location.search

  if (query_string) {
    const hashes = query_string.slice(1).split('&')
    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=')
      vars[hash[0]] = hash[1]
    }

    return vars
  }
  return false
}

export const isClient = typeof window !== 'undefined'

export const compileQuery = (newKey, newValue = 'null', remove = null) => {
  if (isClient) {
    var params = getUrlVars()
  }
  const queryString = []
  if (params) {
    // Add new
    !params[newKey] &&
      newValue != 'null' &&
      queryString.push(`${newKey}=${newValue}`)
    // Update existing
    Object.keys(params).forEach(key => {
      const thisValue = newKey === key ? newValue : params[key]
      thisValue != 'null' &&
        remove != key &&
        queryString.push(`${key}=${thisValue}`)
    })
  } else {
    // Add new
    newValue != 'null' && queryString.push(`${newKey}=${newValue}`)
  }

  return queryString.length > 0 ? `?${queryString.join('&')}` : ''
}

export const addExternalCSS = (href, id, media = 'all') => {
  if (!isBrowser) {
    return
  }
  if (document.getElementById(id)) {
    return
  }

  const head = document.getElementsByTagName('head')[0]
  const link = document.createElement('link')

  link.id = id
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.href = href
  link.media = media

  head.appendChild(link)
}

export const edgeTest = () => {
  // Edge < 40 bug fix
  const appVersion = isClient && navigator.appVersion.indexOf('Edge') > -1
  const appVersionNumber =
    appVersion && navigator.appVersion.split(' Edge/')[1].split(' ')[0]

  if (appVersionNumber && parseInt(appVersionNumber) < 17) {
    return false
  }

  return true
}

const sanitizeHtml = require('sanitize-html')

const htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: "'",
}

export const decodeEntities = str => {
  /* eslint no-useless-escape: 0 */
  /* eslint no-cond-assign: 0 */
  /* eslint no-bitwise: 0 */
  if (!str) return str
  return str.toString().replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match
    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode]
    }
    if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16))
    }
    if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1])
    }
    return entity
  })
}

export const stripHtmlTags = content =>
  sanitizeHtml(content, { allowedTags: [], allowedAttributes: {} })

export const getExcerpt = (content, length) => {
  // Remove html tags
  const cleanedContent = stripHtmlTags(content)
  // Convert html entities to ascii, cut down content to desired length.
  const decodedTrimmedContent = decodeEntities(cleanedContent).substring(
    0,
    length
  )
  // If trimmed content is less than input length, add ellipsis
  const excerpt =
    decodedTrimmedContent.length === length
      ? `${decodedTrimmedContent.substring(
          0,
          decodedTrimmedContent.lastIndexOf(' ')
        )}...`
      : decodedTrimmedContent

  return excerpt
}

/**
 * Gets current window dimensions.
 * @example
 *  const Component = () => {
 *    const { height, width } = useWindowDimensions();
 *    return (
 *     <div>
 *      width: {width} ~ height: {height}
 *     </div>
 *    );
 *  }
 * @returns {width, height} object holding width and height from window.innerWidth, window.innerHeight
 */
export const useWindowDimensions = () => {
  const hasWindow = typeof window !== 'undefined'

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    if (hasWindow) {
      // eslint-disable-next-line no-inner-declarations
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}
