import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { FaAnchor } from 'react-icons/fa'
import { Image } from '../Image'
import { Logo } from '../../../theme/LogoFooter'
import GatsbyLink from '../GatsbyLink'
import { SocialLinks } from '../SocialLinks'
import { decodeEntities } from '../../utils/helpers'
import CookiePolicy from '../CookiePolicy'
import Carousel from '../Carousel'
import './Footer.scss'
import '../../../theme/Footer.scss'

const isClient = typeof window !== 'undefined'

const sliderSettings = {
  slideBy: 1,
  nav: false,
  controls: false,
  mouseDrag: true,
  responsive: {
    2600: {
      items: 6,
    },
    1680: {
      items: 6,
    },
    1080: {
      items: 6,
    },
    700: {
      items: 4,
    },
    310: {
      items: 2,
    },
  },
  speed: 600,
  autoplay: true,
  loop: true,
  lazyLoad: false,
  container: `footer-carousel`,
}

class Footer extends Component {
  sendEmail(event) {
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
    window.location = `mailto:${decodeEntities(email)}`
  }

  render() {
    const { data, location = {} } = this.props
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings,
    } = data
    const { items: legalNav } = legalNavigation
    const { items: footerNav } = footerNavigation
    const {
      footerTagline,
      navigationInFooter,
      contactDetailsInFooter,
      privacyCookiePolicyNotice,
      privacyCookiePolicyNoticeText,
      showFooterAccreditations,
      footerAccreditations,
      preFooterLogoBar,
    } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay,
      businessNumber,
    } = siteSettings.options.locations[0]
    const currentYear = new Date().getFullYear()

    const regex = /(<([^>]+)>)/gi
    const cleanUrlFromTag = url => url.replace(regex, '')

    return (
      <>
        {preFooterLogoBar && preFooterLogoBar.length > 0 && (
          <div className="prefooter-logo-container">
            <div className="content-wrapper">
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: preFooterLogoBar[0].content,
                }}
              />
            </div>
            <div className="logo-wrapper">
              <Carousel settings={sliderSettings}>
                {preFooterLogoBar[0].logoGallery.map(logo => {
                  if (logo.logoImage) {
                    return (
                      <div key={logo.logoImage.id}>
                        {logo.link ? (
                          <GatsbyLink to={logo.link.url}>
                            <img
                              alt={logo.link.title}
                              src={logo.logoImage.source_url}
                              loading="lazy"
                            />
                          </GatsbyLink>
                        ) : (
                            <img src={logo.logoImage.source_url} loading="lazy" />
                        )}
                      </div>
                    )
                  }
                })}
              </Carousel>
            </div>
          </div>
        )}
        <footer>
          <div className="footer-main">
            <div className="inner">
              <div className="wrap">
                <div className="primary-footer-info">
                  {siteSettings.options.socialLinksFooter && <SocialLinks />}
                  <div className="copyright">
                    <strong>
                      <span>
                        © 
                        {' '}
                        {currentYear}
                        {' '}
                        {wordpressWpSettings.title.toUpperCase()}
                      </span>
                    </strong>
                  </div>
                  {businessNumber && (
                    <div className="business-number">
                      <p dangerouslySetInnerHTML={{ __html: businessNumber }} />
                    </div>
                  )}
                  {contactDetailsInFooter && (
                    <div className="contact-details">
                      {addressDisplay && <div>{addressDisplay}</div>}
                      {phone && (
                        <div className="telephone">
                          <a
                            href={`tel:${phone.replace(/[^A-Z0-9]/gi, '')}`}
                            className="control-call"
                            aria-label="Call Now"
                          >
                            {phone}
                          </a>
                        </div>
                      )}
                      {email && (
                        <div className="email">
                          <a href="#" onClick={event => this.sendEmail(event)}>
                            <span dangerouslySetInnerHTML={{ __html: email }} />
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="footer-nav__wrapper">
                  {navigationInFooter && (
                    <ul className="footer-nav">
                      {footerNav &&
                        footerNav.map((item, index) => (
                          <li key={index} className="parent-link">
                            <GatsbyLink
                              to={item.url}
                              aria-label={item.title}
                              decode
                            >
                              {item.title}
                            </GatsbyLink>
                            {item.children &&
                              item.children[0].url !== '#gatsby' && (
                                <ul>
                                  {item.children.map((child, index) => (
                                    <li className="child-link" key={index}>
                                      <GatsbyLink
                                        to={child.url}
                                        aria-label={child.title}
                                      >
                                        {child.title}
                                      </GatsbyLink>
                                    </li>
                                  ))}
                                </ul>
                              )}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
              {showFooterAccreditations && footerAccreditations.length > 0 && (
                <div className="accreditations">
                  <div className="logo-wrapper">
                    {footerAccreditations.map((item, index) => (
                      <div className="item" key={index}>
                        {item.caption ? (
                          <a
                            target="_blank"
                            href={cleanUrlFromTag(item.caption)}
                          >
                            <Image src={item} />
                          </a>
                        ) : (
                          <Image src={item} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="disclaimers">
            <div className="wrap">
              {legalNav && (
                <div className="navigation">
                  <ul>
                    {legalNav.map((item, index) => (
                      <li key={index}>
                        <GatsbyLink to={item.url} decode>
                          {item.title}
                        </GatsbyLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </footer>
        {privacyCookiePolicyNotice && (
          <CookiePolicy content={privacyCookiePolicyNoticeText} />
        )}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
              businessNumber
            }
            footerTagline
            preFooterLogoBar {
              content
              logoGallery {
                logoImage {
                  id
                  source_url
                }
                link {
                  url
                  title
                }
              }
            }
            showFooterAccreditations
            footerAccreditations {
              source_url
              caption
            }
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
