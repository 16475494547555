import React from 'react';
import Helmet from 'react-helmet';
import { useInjectableScript } from '../__HOOKS/use-injectable-script';
import Header from '../Header';
import Footer from '../Footer';
import '../../sass/global/styles.scss';

const Layout = ({ children, location }) => {
  
  const injectableScriptData = useInjectableScript();
  const {injectableScript} = injectableScriptData.wordpressAcfOptions.options;

  return (
    <>
      <div id="layout">
        <Helmet>
          <script src='//fw-cdn.com/1201216/2254493.js' chat='false' />
          {injectableScript && (
            <script async defer>
              {injectableScript}
            </script>
          )}
        </Helmet>
        <Header location={location} />
        <div className="main">{children}</div>
        <Footer location={location} />
      </div>
    </>
  )
}

export default Layout
