import React from 'react'
import GatsbyLink from '../src/components/GatsbyLink'
import myImage from "../static/logos/logo_primary.webp"
import './Logo.scss'

export const Logo = props => {
  return (
    <GatsbyLink to="/" className="logo">
      <img src={myImage} width="170" height="56"/>
    </GatsbyLink>
  );
}