import React, { Component } from 'react'
import 'tiny-slider/src/tiny-slider.scss'
import './Carousel.scss'
import { FaChevronRight } from 'react-icons/fa'

export default class Carousel extends Component {
  componentDidMount() {
    this.initSlider(this.props)
  }

  initSlider = props => {
    const { settings, onChange = () => {}, idContainer = null } = props
    const {
      container = 'slider',
      mode = 'carousel',
      axis = 'horizontal',
      items = 1,
      gutter = 0,
      edgePadding = 0,
      fixedWidth = false,
      autoWidth = false,
      viewportMax = false,
      slideBy = 1,
      center = false,
      controls = true,
      controlsPosition = 'bottom',
      controlsText = ['prev', 'next'],
      controlsContainer = controlsContainer
        ? document.querySelector(controlsContainer)
        : false,
      prevButton = false,
      nextButton = false,
      nav = true,
      navPosition = 'bottom',
      navContainer = false,
      navAsThumbnails = false,
      arrowKeys = false,
      speed = 300,
      autoplay = false,
      autoplayPosition = 'bottom',
      autoplayTimeout = 3000,
      autoplayDirection = 'forward',
      autoplayText = ['start', 'stop'],
      autoplayHoverPause = false,
      autoplayButton = false,
      autoplayButtonOutput = false,
      autoplayResetOnVisibility = true,
      animateIn = 'tns-fadeIn',
      animateOut = 'tns-fadeOut',
      animateNormal = 'tns-normal',
      animateDelay = false,
      loop = true,
      rewind = false,
      autoHeight = false,
      responsive = false,
      lazyload = false,
      lazyloadSelector = 'tns-lazy-img',
      touch = true,
      mouseDrag = false,
      swipeAngle = 15,
      preventActionWhenRunning = false,
      preventScrollOnTouch = false,
      nested = false,
      freezable = true,
      disable = false,
      startIndex = 0,
      onInit = false,
      useLocalStorage = true,
    } = settings
    try {
      // eslint-disable-next-line
      const { tns } = require('tiny-slider/src/tiny-slider.module.js')
      const slider = tns({
        container: idContainer ? `#${idContainer}` : `.${container}`,
        mode,
        axis,
        items,
        gutter,
        edgePadding,
        fixedWidth,
        autoWidth,
        viewportMax,
        slideBy,
        center,
        controls,
        controlsPosition,
        controlsText,
        controlsContainer,
        prevButton,
        nextButton,
        nav,
        navPosition,
        navContainer,
        navAsThumbnails,
        arrowKeys,
        speed,
        autoplay,
        autoplayPosition,
        autoplayTimeout,
        autoplayDirection,
        autoplayText,
        autoplayHoverPause,
        autoplayButton,
        autoplayButtonOutput,
        autoplayResetOnVisibility,
        animateIn,
        animateOut,
        animateNormal,
        animateDelay,
        loop,
        rewind,
        autoHeight,
        responsive,
        lazyload,
        lazyloadSelector,
        touch,
        mouseDrag,
        swipeAngle,
        preventActionWhenRunning,
        preventScrollOnTouch,
        nested,
        freezable,
        disable,
        startIndex,
        onInit,
        useLocalStorage,
      })
      slider && slider.events.on('indexChanged', onChange)
      if (onInit) onInit(slider)
    } catch (error) {
      console.error('Error firing slider', error)
    }
  }

  render() {
    const { idContainer, children, settings } = this.props
    const { container } = settings
    return (
      <div id={idContainer} className={container}>
        {children}
      </div>
    )
  }
}
