import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ls from 'local-storage'
import './SiteNotice.scss'

class ThisSiteNotice extends Component {
  state = {
    showNotice: ls.get('showNotice') === 'hide' ? false : true,
  }

  closeNotice = () => {
    this.setState({ showNotice: false })
    ls.set('showNotice', 'hide')
  }

  render() {
    const { siteSettings } = this.props.data
    const { showNotice } = this.state
    if (siteSettings.options.siteNotice && showNotice === true) {
      return (
        <div className="site-notice">
          <div className="inner">
            <div className="message" dangerouslySetInnerHTML={{__html : siteSettings.options.siteNotice}} />
            <button onClick={this.closeNotice} title="Close">
              X
            </button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export const SiteNotice = props => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions {
          options {
            siteNotice
          }
        }
      }
    `}
    render={data => <ThisSiteNotice data={data} {...props} />}
  />
)
