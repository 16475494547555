import React from 'react';
import { ImagePass } from './ImagePass'
import './Image.scss'

export const getImageUrl = (arrayString) => {
  const srcUrlPass = arrayString
  if ( typeof arrayString === 'string' ) return arrayString
  if ( srcUrlPass && srcUrlPass.url && srcUrlPass.url !== "" ) return srcUrlPass.url // Preview format
  if ( srcUrlPass && srcUrlPass.source_url && srcUrlPass.source_url !== "" ) return srcUrlPass.source_url // Passing URL
  if ( srcUrlPass && srcUrlPass.localFile ) return srcUrlPass.localFile.childImageSharp.fluid.src // Child Sharp Option
  return null
}

export const Image = (props) => {

  const { src, position = "absolute", className, alt, noWrapper, lazy = true } = props;

  if (src === undefined) return null;

  // Looks to be already setup to use gatsby image
  if (src && src.localFile) {
    return <ImagePass {...props} src={src} />
  }

  const srcUrlPass = src && getImageUrl(src)

  if (srcUrlPass === null) return null

  if (srcUrlPass && noWrapper) {
    return <img src={srcUrlPass} className={className} alt={alt} loading="lazy" />
  }

  if ( srcUrlPass ) {
    return (
      <div
        className={`gatsby-image gatsby-image-wrapper${className ? ` ${className}` : '' }`}
        style={{
          position,
          left: 0,
          top: 0,
          width: "100%",
          minHeight:"100%",
        }}
      >
        <img src={srcUrlPass} className={className} alt={alt} loading="lazy" />
      </div>
    )
  }
  

  return (
    <div
      className={`gatsby-image placeholder ${className ? ` ${className}` : '' }`}
      style={{
         position,
         left: 0,
         top: 0,
         width: "100%",
         height: "100%"
       }}
    />
  )
}