import React, { Component } from 'react';

import { decodeEntities } from '../../utils/helpers';

export default class RenderContent extends Component {

  render() {
    const { content = '', excerpt = false, data, className = "entry-content"} = this.props;
    const { render_content = excerpt ? content.split('<!--more-->')[0] : content } = content;
    let new_content = render_content ? render_content.replace('<iframe', '<div class="outer-video"><div class="video"><iframe') : '';
    new_content = new_content ? new_content.replace('</iframe>', '</iframe></div></div>') : '';

    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: decodeEntities(new_content) }}
      />
    );
  }
}
