import { useStaticQuery, graphql } from "gatsby"
export const useInjectableScript = () => {
  return useStaticQuery(
    graphql`
    query wordpressAcfOptions {
      wordpressAcfOptions {
        options {
          injectableScript
        }
      }
    }
  `)
}